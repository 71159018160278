import tinycolor from "tinycolor2";

const GLOBAL_NAMESPACE = "--sds-g-";
const SHARED_NAMESPACE = "--sds-s-";

/**
 * Background and foreground colors to monitor for correct contrast when
 * colors are dynamically changed.
 */
export const watchContrast = [
  {
    background: "--sds-g-color-brand-base-95",
    foreground: "--sds-g-color-brand-base-contrast-95",
  },
  {
    background: "--sds-g-color-brand-base-40",
    foreground: "--sds-g-color-brand-base-contrast-40",
  },
];

export const generateCustomProperties = (
  hooks,
  type,
  sharedNamespace,
  noIncrement
) => {
  const styles = [];
  const namespace = sharedNamespace ? SHARED_NAMESPACE : GLOBAL_NAMESPACE;
  for (const hook in hooks) {
    const customProperty = namespace + hook;
    const increment = !noIncrement && `* var(--increment-${type}, 1)`;
    const value = `calc(${hooks[hook]} ${!noIncrement && increment} )`;
    const declaration = `${customProperty}: ${value};`;
    styles.push(declaration);
  }
  const formattedStyles = styles.join("\n");
  return formattedStyles;
};

export const filterStylingHooks = (hooks, filter) => {
  const filteredHooks = {};
  for (const hook in hooks) {
    if (hook.includes(filter)) {
      filteredHooks[hook] = hooks[hook];
    }
  }
  return filteredHooks;
};

export const cleanStylingHooksJSON = (json) => {
  const cleanedHooks = {};
  for (const category in json) {
    for (const prop in json[category]) {
      cleanedHooks[prop] = `var(--${prop}-value)`;
    }
  }
  return cleanedHooks;
};

// LUL, LUL I SAY
// Creates the hook at the root that sets the base or increment value that
// other hooks are based on.
export const setIncrement = (type, value, scope, unit) => {
  const incrementScope = scope || "increment";
  let newValue = unit ? value + unit : value;
  document.documentElement.style.setProperty(
    `--${incrementScope}-${type}`,
    newValue
  );
};

export const updateUIValue = (el, newValue) => {
  const target = el.nextElementSibling;
  target.innerHTML = newValue;
};

export const RGBToHSL = (r, g, b) => {
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return "hsl(" + h + "," + s + "%," + l + "%)";
};

export const getProperty = (property) => {
  return getComputedStyle(document.documentElement).getPropertyValue(property);
};

export const setProperty = (property, value) => {
  document.documentElement.style.setProperty(property, value);
};

/**
 * Check the color contrast between a background and foreground. If contrast
 * fails, update the lightness of the foreground color.
 *
 * @param {array} colors - An array of objects with background & foreground pairing
 */
export const checkContrast = (colors) => {
  colors.forEach((color) => {
    // Determine the luminance and lightness based on the background color
    const background = color.background;
    const value = getProperty(background);
    const hslValues = value.split(",");
    const h = hslValues[0].substring(5);
    let s = hslValues[1].match(/[+-]?\d+(\.\d+)?/g);
    s = s[0] * s[1] * s[2];
    let l = hslValues[2].match(/[+-]?\d+(\.\d+)?/g);
    l = l[0] * l[1] * l[2];
    const luminance = tinycolor(`hsl(${h}, ${s}%, ${l}%)`).getLuminance();
    const lightness = luminance < 0.3 ? 95 : 15;

    // Update the forerground color based on the background
    const values = getProperty(background).split(",");
    const newValue = `${values[0]}, ${values[1]}, ${lightness}%)`;
    setProperty(color.foreground, newValue);
  });
};
